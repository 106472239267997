
<script>
export default {
	name: "PrintAmountInput",
    props: {
        card: Object,
        size: {
            default: null,
            type: String
        }
	},
    methods: {
        getPrintAmount(cardSlug) {
			return this.$store.getters.getPrintAmount(cardSlug)
		},
        setPrintCardAmount (card, amount) {
			this.$store.commit('setPrintCardAmount', { card, amount})
		},
    }
};
</script>

<template>
	<b-form-spinbutton
        :value="getPrintAmount(card.slug)"
        min="0"
        max="99"
        step="1"
        :size="size"
        @input="setPrintCardAmount(card, $event)"
    ></b-form-spinbutton>
</template>


<style lang="scss" scoped>

</style>


