<script>
export default {
	name: "StatTiles",
	props: {
		stats: {
            type: Array,
            default() {
                return []
            } //[ {label, value} ]
        },
	},
    data() {
		return {
			statWithIcons: ['power', 'durability', 'life', 'speed'],
		};
	},
	computed: {
		imageURL() {
			return `${this.baseURL}/${this.card.slug}.png`;
		},
	},
};
</script>

<template>
	<div class="stats">

        <div
            v-for="stat in stats"
            :key="stat.label"
            class="stats__tile"
            :class="{'stats__tile--solo' : stat.solo }"
        >
			<div
                v-if="!stat.solo"
                class="stats__tile__label"
            >
                {{ stat.label }}
            </div>
			<div class="stats__tile__value">
                <span
                    v-if="statWithIcons.indexOf(stat.key) > -1"
                    class="stats__tile__value__icon"
                    :class="[`stats__tile__value__icon--${stat.key}`]"
                ></span>
                {{ stat.displayValue || stat.value }}
            </div>
		</div>
		<!-- <div
            v-for="stat in stats"
            :key="stat.label"
            class="stats__tile"
        >
			<div class="stats__tile__label">{{ stat.label }}</div>
			<div class="stats__tile__value">{{ stat.value }}</div>
		</div> -->
        <!-- <div class="stats__tile stats__tile-solo">
            <div class="stats__tile__value">
                Fast
            </div>
        </div> -->
		<!-- <div class="stats__tile">
        <div class="stats__tile__label">
            Attack
        </div>
        <div class="stats__tile__value">
            1
        </div>
    </div>
    <div class="stats__tile">
        <div class="stats__tile__label">
            Durability
        </div>
        <div class="stats__tile__value">
            3
        </div>
    </div>
    <div class="stats__tile">
        <div class="stats__tile__label">
            Durability
        </div>
        <div class="stats__tile__value">
            3
        </div>
    </div> -->
	</div>
</template>


<style lang="scss">
.stats {
	display: flex;
	flex-wrap: wrap;
	&__tile {
		flex-basis: 31.833333%;
		background: rgba(255,255,255, 0.15);
		margin: 0.7% 0.7%;
		padding: 10px 0;
		border-radius: 4px;
		&__label {
			text-align: center;
			font-size: 0.7rem;
			// background: blue;
		}
		&__value {
			text-align: center;
			font-size: 1.3rem;
			// background: red;
            &__icon {
                display: inline-block;
                
                vertical-align: text-bottom;
                margin-bottom: 2px;

                mask-size: contain;
                mask-position: center;
                mask-repeat: no-repeat;
                background-color: white;

                &--power {
                    mask-image: url(/images/icons/sword.svg);
                    width: 19px;
                    height: 19px;
                }
                &--durability {
                    mask-image: url(/images/icons/shield.svg);
                    width: 22px;
                    height: 22px;
                }
                &--life {
                    mask-image: url(/images/icons/heart.svg);
                    width: 20px;
                    height: 20px;
                }
                &--speed {
                    mask-image: url(/images/icons/thunder.svg);
                    width: 12px;
                    height: 18px;

                    // display: block;
                    // margin-right: 0;
                    // margin-bottom: 0;
                }
            }
		}

        // tile with one single text on top
        &--solo {
            padding: 10px 0;
        }
	}
}
</style>
