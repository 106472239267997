<script>
// @ is an alias to /src
import DataService from "@/services/data";
import TextService from "@/services/text";
import config from "@/config";


import StatTiles from "@/components/StatTiles.vue";
import CardImage from "@/components/CardImage.vue";
import TypeIcon from "@/components/TypeIcon.vue";
import ElementIcon from "@/components/ElementIcon.vue";
import PrintAmountInput from "@/components/PrintAmountInput";

import { SlideYUpTransition, SlideXLeftTransition, SlideYDownTransition } from 'vue2-transitions'

import meta from "@/mixins/meta.js"
import { Disqus } from 'vue-disqus'

export default {
	name: "Card",
	mixins: [meta],
	components: {
        
		StatTiles,
        CardImage,
		TypeIcon,
		Disqus,
		ElementIcon,
		PrintAmountInput,

        SlideYUpTransition,
        SlideYDownTransition,
        SlideXLeftTransition
	},
	data() {
		return {
			card: null,

			hoverEditionSlug: null,
            hoverCardName: null,

			enableDisqus: config.enable.disqus,
			disqusShortname: config.disqus.shortname,

			printingTooltip: 'This card is eligible for reprints in this edition.\nThe population number will be updated periodically.',
            printingFixedTooltip: 'This edition of this card is out of print.\nThe population number will never change.',

            showThema: true,
            themaOptionsSelect: 'nonfoil',

            effectEditionRefSlug: null,
            chartOptions: {
                labels: ['Grace', 'Valor', 'Charm', 'Mystique', 'Ferocity'],
                colors: ['#0865fc', '#fc9f08', '#dc96ff', '#8800ff', '#d41500'],
                legend: {
                    position: 'top',
                    labels: {
                        colors: '#fff'
                    }
                },
                dataLabels: {
                    enabled: true,
                    formatter: function (val, opts) {
                        return opts.w.config.series[opts.seriesIndex]
                    },
                    style: {
                        colors: ['#fff'],
                        fontSize: '17px',
                        fontFamily: '"Roboto", sans-serif',
                        fontWeight: 400
                    },
                    dropShadow: {
                        enabled: true,
                        top: 1,
                        left: 1,
                        blur: 3,
                        color: '#000',
                        opacity: 0.6
                    }
                },
                stroke: {
                    show: false
                },
                plotOptions: {
                    pie: {
                        expandOnClick: false,
                        donut: {
                            size: '60',
                            labels: {
                                show: true,
                                name: {
                                    show: true,

                                },
                                value: {
                                    show: true,
                                    fontFamily: '"Poppins", sans-serif',
                                    fontSize: '1rem',
                                    fontWeight: 400,
                                    color: '#fff',
                                },
                                total: {
                                    show: true,
                                    fontFamily: '"Poppins", sans-serif',
                                    fontSize: '1.4em',
                                    fontWeight: 400,
                                    color: '#fff',
                                    label: 'Total Score'
                                }
                            },
                            
                        }
                    }
                }
            }
		};
	},
	methods: {
		getData(slug) {
			DataService.getCard(slug)
				.then((response) => {
					this.card = response.data;
					this.setTitle(this.card.name)
				})
				.catch((e) => {
					console.log(e);
				});
		},
		getEditionData(editionSlug) {
			DataService.getEdition(editionSlug)
				.then((response) => {
					this.card = response.data;
					this.setTitle(this.card.name)
				})
				.catch((e) => {
					console.log(e);
				});
		},
		resetDisqus() {
			this.$refs.disqus.reset()
		},
		onNameMouseEnter ({name, slug}) {
            this.hoverEditionSlug = slug;
            this.hoverCardName = name;
            if (this.$refs.cardTooltip) this.$refs.cardTooltip.tippy().show()
        },
        onNameMouseLeave () {
            this.hoverCardSlug = null;
            this.hoverEditionSlug = null;
            if (this.$refs.cardTooltip) this.$refs.cardTooltip.tippy().hide()
        },
        onEffectEditionRefClick (event) {
            const element = event.target;
            console.log('onEffectEditionRefClick')
            console.log(element.classList.contains("effect__edition-hover"))
            console.log('----------')

            if (
                element.classList.contains("effect__edition-hover")
                && element.getAttribute('data-edition-slug')
                ) {
                
                this.$router.push({ name: 'Edition', params: { editionSlug: element.getAttribute('data-edition-slug') }})
                this.effectEditionRefSlug = null
                this.$refs.effectCardRefTooltip.tippy().hide()
            }
        },
        onEffectEditionRefMouseEnter (event) {
            const element = event.target;
            // console.log('onEffectEditionRefMouseEnter')
            // console.log(element.classList.contains("effect__edition-hover"))
            // console.log('----------')

            if (element.classList.contains("effect__edition-hover")) {
                // console.log(element.getAttribute('data-edition-slug'))

                this.effectEditionRefSlug = element.getAttribute('data-edition-slug')
                this.$refs.effectCardRefTooltip.tippy().show()
            }
        },
        onEffectEditionRefMouseOut (event) {
            const element = event.target;
            // console.log('onEffectEditionRefMouseOut')
            // console.log(element.classList.contains("effect__edition-hover"))
            // console.log('----------')
            if (element.classList.contains("effect__edition-hover")) {

                this.effectEditionRefSlug = null
                this.$refs.effectCardRefTooltip.tippy().hide()
            }
        },
        setShowThema (value) {
            this.showThema = value
            this.$cookies.set('showThema', value)
        },
		getRarityString: TextService.getRarityString,
		getOperatorString: TextService.getOperatorString,
        toMonthDateYear: TextService.toMonthDateYear,
		parseType: TextService.parseType,
		capitalizeFirstChar: TextService.capitalizeFirstChar,
		getCardSubtitle: TextService.getCardSubtitle,
	},
	computed: {
        displayMode () {
            if (this.$route.params.slug) {
                return 'card'
            }
            
            if (this.$route.params.editionSlug) {
                return 'edition'
            }

            return null
        },
		classification () {
			return TextService.parseType(this.card.classes)
		},
		type() {
			return TextService.parseType(this.card.types)
		},
		subtype() {
			return TextService.parseType(this.card.subtypes)
		},
		stats() {
			const intStats = []
			// get all int stats to display in stat tiles
			for (const [key, value] of Object.entries(this.card)) {
				if (Number.isInteger(value)) {
					intStats.push({
                        key,
						label: TextService.parseLabel(key),
						value,
                        // show X for these stats if the value is -1
                        displayValue: (key === 'cost_memory' |  key === 'cost_reserve') && (value === -1) ? "X" : value
					})
				}
                //also include 'speed' attribute
                if (key === 'speed' && typeof value === 'boolean') {
					intStats.push({
                        key,
						label: 'Speed',
						value: value ? 'Fast' : 'Slow',
                        // solo: true
					})
				}
			}
			return intStats;
			// return [{ label: 'Power', value: 1}, { label: 'Memory Cost', value: 3}]
		},
        rules () {
            if(!this.card.rule) return []

            const rules = this.card.rule
                .map(r => {
                    const date = new Date(r.date_added)
                    return {
                        title: r.title,
                        description: r.description,
                        date: this.toMonthDateYear(r.date_added),
                        timestamp: date.getTime()
                    }
                })
                .sort((a, b) => {
                    return b.timestamp - a.timestamp
                })

            return rules;
        },
		effectLines() {
			if (this.mostCommonEdition && this.mostCommonEdition.effect)
				return this.mostCommonEdition.effect.split(/\n/)

			if (this.card.effect) return this.card.effect.split(/\n/)

			return []
		},
		flavor() {

            if (
                this.mostCommonEdition &&
                this.mostCommonEdition.flavor
            ) return this.mostCommonEdition.flavor

            if (this.card.flavor) return this.card.flavor

            return null
		},
		defaultEdition() {
			if (this.card.default_edition_id && this.card.editions.length> 0) {
				return this.card.editions.filter(edition => edition.uuid === this.card.default_edition_id)[0]
			}
			return null
		},
		mostCommonEdition() {

			// two use cases
			// return the most common edition
			// or return the selected result_edition (the first result)
			if (this.card.result_editions.length) {
				return this.card.result_editions[0] // result_editions and editions are sorted by the API from lowest rarity to highest
			}

			if (this.card.editions.length) {
				return this.card.editions[0]
			}

			return {
                slug: 'no-edition'
            }
		},
        subtitle() {
			return this.getCardSubtitle({
				types: this.card.types,
				classes: this.card.classes,
				subtypes: this.card.subtypes
			})
        },
		numberCode () {
			if (this.mostCommonEdition && this.mostCommonEdition.set)
				return `${this.mostCommonEdition.set.prefix} • ${this.mostCommonEdition.set.language} — ${this.mostCommonEdition.collector_number}`
			
			return null
		},
        legality () {
            // create default legality and add exception from individual card legality
            const gameFormats = this.$searchOptions.gameFormat
            if (!gameFormats) return

            const legality = gameFormats
                .map((g) => {
                    // const cardLegality = this.card.legality.filter(l => l.gameFormat === g.value)[0]
                    if (this.card.legality) {
                        const cardLegality = Object.entries(this.card.legality).filter(([value]) => value === g.value)[0];
                        // return cardLegality
                        if (cardLegality) {
                            return {
                                format: g.text,
                                value: cardLegality[1].limit === 0 ? 'banned' : 'limit',
                                text: cardLegality[1].limit === 0 ? 'Banned' : `Restricted to ${cardLegality[1].limit}`
                            }
                        }
                    }
                    
                    return {
                        format: g.text,
                        value: "legal",
                        text: "Legal"
                    }
                })

            return legality
        },
        thema () {
            if (this?.mostCommonEdition.slug === 'no-edition' ) return null
            return {
                // labels: ['Grace', 'Valor', 'Charm', 'Mystique', 'Ferocity'],
                nonfoil: {
                    grace: this.mostCommonEdition.thema_grace_nonfoil,
                    valor: this.mostCommonEdition.thema_valor_nonfoil,
                    charm: this.mostCommonEdition.thema_charm_nonfoil,
                    mystique: this.mostCommonEdition.thema_mystique_nonfoil,
                    ferocity: this.mostCommonEdition.thema_ferocity_nonfoil,
                    sum: this.mostCommonEdition.thema_nonfoil
                },
                foil: {
                    grace: this.mostCommonEdition.thema_grace_foil,
                    valor: this.mostCommonEdition.thema_valor_foil,
                    charm: this.mostCommonEdition.thema_charm_foil,
                    mystique: this.mostCommonEdition.thema_mystique_foil,
                    ferocity: this.mostCommonEdition.thema_ferocity_foil,
                    sum: this.mostCommonEdition.thema_foil
                }
            }
        },
        themaChartSeries () {
            if (!this.thema) return []
            // order of the array is very important. Must match chart label array order
            // labels: ['Grace', 'Valor', 'Charm', 'Mystique', 'Ferocity'],
            
            let t
            if (this.themaOptions.length === 1) {
                t = this.thema[ this.themaOptions[0].value ]
            } else {
                t = this.thema[ this.themaOptionsSelect === 'foil' ? 'foil' : 'nonfoil' ]
            }

            
            return [t.grace, t.valor, t.charm, t.mystique, t.ferocity]
        },
        themaExists () {
            return this.thema.nonfoil.sum > 0 || this.thema.foil.sum > 0
        },
        themaOptions () {
            /*
            themaOptions: [
                { text: 'Normal', value: 'normal' },
                { text: 'Foil', value: 'foil' }
            ],
            */

            const options = []

            if (this.thema.nonfoil.sum) options.push({ text: 'Normal', value: 'nonfoil' })
            if (this.thema.foil.sum) options.push({ text: 'Foil', value: 'foil' })

            return options
        }
	},
	mounted() {
		// when looking for a card by card slug from route /card/:slug
		if (this.$route.params.slug) {
			this.getData(this.$route.params.slug);
		}
		
		// when looking for a card by editionSlug from route /edition/:editionSlug
		if (this.$route.params.editionSlug) {
			this.getEditionData(this.$route.params.editionSlug);
		}

        if (!this.$cookies.isKey('showThema')) {
            this.$cookies.set('showThema', true)
        } else {
            this.showThema = this.$cookies.get('showThema') === 'true'
        }


        window.scrollTo(0, 0)
	},
	beforeRouteUpdate (to, from, next) {

        
		
		if (to.params.slug && to.params.slug !== this.card.slug) {
			this.getData(to.params.slug);
		}
		if (to.params.editionSlug && to.params.editionSlug !== this.mostCommonEdition.slug) {
			this.getEditionData(to.params.editionSlug);
		}

		
		next()
	},
	beforeRouteLeave (to, from, next) {
        
		// from /edition to /card
		if (to.params.slug && this.mostCommonEdition.slug) {
			this.getData(to.params.slug);
		}

		// from /card to /edition
		if (to.params.editionSlug && this.card.slug) {
			this.getEditionData(to.params.editionSlug);
		}

		next();
	}
};
</script>

<template>
	<div class="page" >
		<b-container
            v-if="card"
			class="page__card"
		>
			<b-row>
                
                <slide-y-up-transition appear :duration=500 mode="out-in">
                    <!-- using :key to trigger transition when the card changes -->
                    <b-col :key="`header__${card.slug}`">
                            <h4 class="page__card__title">
                                <ElementIcon
                                    :element="card.element.toLowerCase()"
                                    :title="capitalizeFirstChar(card.element)"
                                    class="mr-2"
                                    small-card-page
                                />{{ card.name }}
                            </h4>
                            <div class="page__card__classifications mb-2">
                                <TypeIcon
                                    :types="card.types"
                                    :title="type"
                                />{{ subtitle }}
                            </div>
                    </b-col>
                </slide-y-up-transition>
			</b-row>
			<b-row>
				<b-col
					lg="4"
					md="6"
					cols="12"
					class="pr-lg-2 mb-2 text-center"
				>
                    
                    <slide-x-left-transition appear :duration=500 mode="out-in">
                        <CardImage
                            :slug="mostCommonEdition.slug"
                            :alt="card.name"
                            :key="mostCommonEdition.slug"
                            :initial="true"
                        />
                    </slide-x-left-transition>
                    <slide-y-down-transition appear :duration=500 mode="out-in">
                        <div
                            :key="`pnp__${card.slug}`"
                            class="mx-2 mt-3 mb-1 mb-lg-0 text-left"
                        >
                            <b-row>
                                <b-col cols="6">
                                    <div class="mt-1">
                                        <b-icon-printer scale="1.1" class="mr-1" />
                                        Print & Play
                                    </div>
                                </b-col>
                                <b-col cols="6">
                                    <PrintAmountInput
                                        :card="card"
                                    />
                                </b-col>
                            </b-row>
                        </div>
                    </slide-y-down-transition>
				</b-col>
				<b-col
					lg="4"
					md="6"
					cols="12"
					class="px-lg-2"
				>
                    <!-- for card ref hover in effect -->
                    <tippy
                        ref="effectCardRefTooltip"
                        :animate-fill="false"
                        :flip-on-update="true"
                        animation="none"
                        theme="no-bg"
                        follow-cursor
                        placement="right-end"
                        max-width="300px"
                    >
                        <CardImage
                            v-if="effectEditionRefSlug"
                            :slug="effectEditionRefSlug"
                            alt=""
                        />
                    </tippy>

                    <slide-x-left-transition appear mode="out-in" :delay="100">
                        <div :key="`data__${mostCommonEdition.slug}`">
                            <b-list-group
                                class="attribute-list"
                            >
                                <b-list-group-item class="p-1">
                                    <div class="attribute-list__title">Stats</div>
                                    <StatTiles :stats="stats" />
                                </b-list-group-item>
                                <b-list-group-item
                                    v-if="effectLines.length"
                                    class="effect p-1"
                                >
                                    <div class="attribute-list__title">Effect</div>
                                    <p
                                        v-for="(line, index) in effectLines"
                                        :key="index"
                                        v-html="line"
                                        class="px-2 pb-1"
                                        @mouseenter.capture="onEffectEditionRefMouseEnter"
                                        @mouseout.capture="onEffectEditionRefMouseOut"
                                        @click.capture="onEffectEditionRefClick"
                                    >
                                    </p>
                                </b-list-group-item>
                                <b-list-group-item
                                    v-if="flavor"
                                    class="p-2"
                                >
                                    <p class="mb-1 font-italic" style="white-space: pre-wrap;">{{ flavor }}</p>
                                </b-list-group-item>
                                <b-list-group-item
                                    v-if="mostCommonEdition && mostCommonEdition.illustrator"
                                    class="p-1"
                                >
                                    <div class="attribute-list__title">Illustrated by</div>
                                    <p class="mb-1 px-2 pb-1">
                                        <router-link
                                            :to="{ name: 'SearchResult', query: { illustrator: mostCommonEdition.illustrator } }"
                                        >
                                            {{ mostCommonEdition.illustrator }}
                                        </router-link>
                                    </p>
                                </b-list-group-item>
                            </b-list-group>

                            <b-list-group
                                :key="`rules__${mostCommonEdition.slug}`"
                                class="attribute-list mt-3"
                                v-if="rules.length"
                            >
                                <b-list-group-item class="p-2">
                                    <div class="attribute-list__title">Rules</div>

                                    <div
                                        v-for="(rule, index) in rules"
                                        :key="index"
                                        class="rule"
                                    >
                                        <div class="rule__title">{{ rule.title }}</div>
                                        <div class="rule__date">{{ rule.date }}</div>
                                        <p>
                                            {{ rule.description }}
                                        </p>
                                    </div>
                                </b-list-group-item>
                            </b-list-group>

                        </div>
                    </slide-x-left-transition>
				</b-col>

                <slide-x-left-transition appear mode="out-in" :delay="200">
                    <b-col
                        :key="`editions__${mostCommonEdition.slug}`"
                        v-if="mostCommonEdition && mostCommonEdition.set"
                        class="pl-lg-2 mt-3 mt-lg-0"
                    >
                        <b-list-group
                            class="attribute-list"
                        >
                            <b-list-group-item class="p-2">
                                <h5>
                                    <router-link
                                        :to="{ name: 'SearchResult', query: { prefix: mostCommonEdition.set.prefix } }"
                                    >
                                        {{ mostCommonEdition.set.name }}
                                    </router-link>
                                </h5>
                                <div class="d-flex w-100 justify-content-between">
                                    <span>{{ numberCode }}</span>
                                    <span
                                        class="rarity__text mr-2"
                                        :class="[`rarity__text--${mostCommonEdition.rarity}`]"
                                    >
                                        {{ getRarityString(mostCommonEdition.rarity) }}
                                    </span>
                                </div>
                            </b-list-group-item>
                            <b-list-group-item
                                v-if="mostCommonEdition.circulationTemplates.length || mostCommonEdition.circulations.length"
                                class="p-1"
                            >
                                <!-- default edition's population and circulation -->
                                <div class="attribute-list__title">population</div>
                                <b-table-simple
                                    class="attribute-table mb-0"
                                >
                                    <b-tbody>
                                        <b-tr
                                            v-for="(template, index) in mostCommonEdition.circulationTemplates"
                                            :key="index"
                                        >
                                            <b-td>
                                                {{ template.foil ? "Foil" : "Normal" }}
                                            </b-td>
                                            <b-td
                                                class="text-right"
                                            >
                                                {{ getOperatorString(template.population_operator) }} {{ template.population ? template.population.toLocaleString() : '' }}
                                                <b-icon
                                                    v-if="template.printing !== null || template.printing !== null"
                                                    :icon="template.printing === false ? 'check2-circle' : 'arrow-clockwise'"
                                                    scale="1.15"
                                                    class="ml-1"

                                                    :content="template.printing === false ? printingFixedTooltip : printingTooltip" 
                                                    v-tippy="{ 
                                                        placement: 'bottom-end',
                                                        arrow: true,
                                                        theme: 'grey',
                                                        distance: 10,
                                                        allowHTML: false,
                                                    }"
                                                />
                                            </b-td>
                                        </b-tr>
                                        <b-tr
                                            v-for="(circulation, index) in mostCommonEdition.circulations"
                                            :key="`c${index}`"
                                        >
                                            <b-td>
                                                {{ circulation.foil ? "Foil" : "Normal" }}
                                            </b-td>
                                            <b-td
                                                class="text-right"
                                            >
                                                {{ getOperatorString(circulation.population_operator) }} {{ circulation.population ? circulation.population.toLocaleString() : '' }}
                                                <b-icon
                                                    v-if="circulation.printing !== null"
                                                    :icon="circulation.printing === false ? 'check2-circle' : 'arrow-clockwise'"
                                                    scale="1.15"
                                                    class="ml-1"

                                                    :content="circulation.printing === false ? printingFixedTooltip : printingTooltip" 
                                                    v-tippy="{ 
                                                        placement: 'bottom-end',
                                                        arrow: true,
                                                        theme: 'grey',
                                                        distance: 10,
                                                        allowHTML: false,
                                                    }"
                                                />
                                            </b-td>
                                        </b-tr>
                                    </b-tbody>
                                </b-table-simple>

                                

                                
                            </b-list-group-item>
                            <b-list-group-item
                                v-if="themaExists"
                                class="p-0"
                            >
                                <div
                                    @click="setShowThema(!showThema)"
                                    class="attribute-list__title attribute-list__thema p-2"
                                >  
                                    Thema
                                    <b-icon
                                        v-if="!showThema"
                                        icon="caret-down-fill"
                                        scale="1"
                                        class="ml-2"
                                    />

                                    
                                    
                                </div>
                                <slide-y-up-transition :duration=400 mode="out-in">
                                    <div
                                        v-if="showThema"
                                        class="thema"
                                    >
                                        <b-form-radio-group
                                            v-if="themaOptions.length > 1"
                                            v-model="themaOptionsSelect"
                                            :options="themaOptions"
                                            buttons
                                            class="thema__options text-capitalize px-2 pb-2 right"
                                        ></b-form-radio-group>
                                        <apexchart
                                            v-if="themaChartSeries.length > 0"
                                            width="100%"
                                            type="donut"
                                            :options="chartOptions"
                                            :series="themaChartSeries"
                                        ></apexchart>
                                        
                                        <div
                                            @click="showThema = false"
                                            class="text-center thema__hide-button pb-2"
                                        >
                                            <b-icon
                                                icon="caret-up-fill"
                                                scale="0.85"
                                                class="mr-1"
                                            />
                                            Hide Card Thema
                                            <b-icon
                                                icon="caret-up-fill"
                                                scale="0.85"
                                                class="ml-1"
                                            />
                                        </div>
                                    </div>
                                </slide-y-up-transition>
                            </b-list-group-item>
                            

                        
                        </b-list-group>


                        <b-list-group
                            class="attribute-list mt-3"
                            v-if="card.editions.length > 1"
                        >
                            <!-- Edition Image toolips -->
                            <tippy
                                ref="cardTooltip"
                                :animate-fill="false"
                                animation="none"
                                theme="no-bg"
                                follow-cursor
                                placement="right-end"
                                max-width="300px"
                            >
                                <CardImage
                                    v-if="hoverEditionSlug"
                                    :slug="hoverEditionSlug"
                                    :alt="hoverCardName"
                                    :initial="true"
                                />
                            </tippy>
                            <b-list-group-item class="p-2">
                                <div class="attribute-list__title">All Editions</div>
                            </b-list-group-item>
                            <b-list-group-item
                                v-for="(edition, index) in card.editions"
                                :key="index"
                                class="py-2 px-2"
                                :active="edition.slug === mostCommonEdition.slug"
                                :to="edition.slug !== mostCommonEdition.slug ? { name: 'Edition', params: { editionSlug: edition.slug }} : null"
                                @mouseenter="onNameMouseEnter({ name: card.name, slug: edition.slug })"
                                @mouseleave="onNameMouseLeave"
                            >

                                <div class="d-flex w-100 justify-content-between">
                                    <!-- <span>{{ edition.set.prefix }} • {{ edition.set.language }} — {{ edition.collector_number }}</span> -->
                                    <span>{{ edition.set.name }}</span>
                                    <span
                                        class="rarity__text mr-2"
                                        :class="[`rarity__text--${edition.rarity}`]"
                                    >
                                        {{ getRarityString(edition.rarity) }}
                                    </span>
                                </div>
                                
                            </b-list-group-item>
                        </b-list-group>


                        <b-list-group
                            v-if="legality"
                            class="attribute-list mt-3"
                        >
                            <b-list-group-item class="p-2">
                                <div class="attribute-list__title">Legality</div>
                            </b-list-group-item>
                            <b-list-group-item
                                v-for="(legal, index) in legality"
                                :key="index"
                                class="py-2 px-2"
                            >

                                <div class="d-flex w-100 justify-content-between">
                                    <span>{{ legal.format }}</span>
                                    <span
                                        class="legal__text mr-2"
                                        :class="[`legal__text--${legal.value}`]"
                                    >
                                        {{ legal.text }}
                                    </span>
                                </div>
                                
                            </b-list-group-item>
                        </b-list-group>
                    </b-col>
                </slide-x-left-transition>
			</b-row>

			<b-row
				v-if="enableDisqus"
			>
                <slide-y-down-transition appear :duration=400 mode="out-in">
                    <b-col
                        :key="`disqus__${card.slug}`"
                        class="mt-3"
                    >
                        <b-list-group>
                            <b-list-group-item>
                                <!-- <b-btn
                                    @click="resetDisqus"
                                >
                                    Force Disqus Reset
                                </b-btn> -->
                                <Disqus
                                    v-if="mostCommonEdition && mostCommonEdition.slug"
                                    :key="mostCommonEdition.slug"
                                    ref="disqus"
                                    :shortname="disqusShortname"
                                    :page-config="{
                                        identifier: mostCommonEdition.slug,
                                        title: `${card.name} (${numberCode})`,
                                    }"
                                />
                            </b-list-group-item>
                        </b-list-group>
                    </b-col>
                </slide-y-down-transition>
			</b-row>

		</b-container>
	</div>
</template>

<style lang="scss">
.effect {
	font-size: 0.85rem;
	white-space: pre-wrap;
	&__bubble {
		background: rgba(0,0,0,0.8);
		padding: 2px 6px;
		margin-right: 2px;
		left: -4px;
		position: relative;
		border-radius: 10px;
        &--yellow {
            color: #000;
            background: #E6D24B;
        }
	}
    &__edition-hover {
        cursor: pointer;
    }
    &__correction {
        // should appear as normal text in raw text
        color: orange;
        font-weight: bold;
    }
	&__label {
		font-weight: bold;
	}
	&__reminder {
		font-style: italic;
	}
	&__icon {
		display: inline-block;
		width: 13px;
		height: 13px;
		vertical-align: text-top;
		background-position: center;
		background-size: 13px;
		margin: 0 2px;

		mask-size: contain;
		mask-position: center;
		mask-repeat: no-repeat;
		background-color: white;
	}
	&__icon--sword {
		mask-image: url(/images/icons/sword.svg);
	}
	&__icon--heart {
		mask-image: url(/images/icons/heart.svg);
	}
	&__icon--rest {
		background-repeat: no-repeat;
		background-color: transparent;
		background-image: url(/images/icons/rest.png);
		vertical-align: top;
		background-size: 26px;
		width: 30px;
		height: 18px;
		margin: 0;
	}
}

</style>

<style lang="scss" scoped>

.attribute-list {
	&__title {
		text-transform: uppercase;
		font-size: 0.55rem;
		color: rgba(255,255,255, 0.5)
	}

    &__thema {
        cursor: pointer;
        
    }
    &__thema:hover {
        color: #fff
    }
}
.attribute-table {
	font-size: 0.85rem;
	td {
		border-top: none;
	}
	tr:first-child {
		border-top: none;
	}
	tr {
		border-top: 1px solid #444;
	}
}
.rule {
    border-bottom: 1px solid #505050;
    margin-bottom: 10px;
    &__title {
        text-transform: uppercase;
		font-size: 0.75rem;
    }
    &__date {
        font-size: 0.75rem;
    }
}
.rule:last-child {
    border-bottom: none;
    margin-bottom: 0;
}
.thema {
    &__hide-button {
        text-transform: uppercase;
		font-size: 0.8rem;
		color: rgba(255,255,255, 0.5);
        cursor: pointer;
    }
    text-align: center;
    &__options {
        width: 100%
    }
}
</style>
